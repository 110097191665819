import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="referral"
export default class extends Controller {
  static targets = [
    'serviceFee',
    'referralFee',
    'referralFeePercent',
    'referralFeePercentContainer',
    'additionalFee',
    'taxRequired',
    'taxPercent',
    'taxPercentContainer',
    'taxAmount',
    'clientFirstName',
    'clientLastName',
    'email1',
    'email2',
  ]

  ensureReferralPercentRange() {
    const referralFeePercent = parseFloat(this.referralFeePercentTarget.value)
    if (referralFeePercent > 0.0 && referralFeePercent < 100.0) {
      this.referralFeePercentContainerTarget.classList.remove(
        'field_with_errors',
      )
      return true
    } else {
      this.referralFeePercentContainerTarget.classList.add('field_with_errors')
      return false
    }
  }

  ensureTaxPercentRange() {
    const taxPercent = parseFloat(this.taxPercentTarget.value)
    if (taxPercent > 0.0 && taxPercent < 100.0) {
      this.taxPercentContainerTarget.classList.remove('field_with_errors')
      return true
    } else {
      this.taxPercentContainerTarget.classList.add('field_with_errors')
      return false
    }
  }

  warnOnDuplicateClients(event) {
    event.preventDefault();
    const form = document.getElementsByClassName('referral-form')[0]
    const clientFirstName = document.getElementById('referral_client_first_name').value
    const clientLastName = document.getElementById('referral_client_last_name').value
    const email1 = document.getElementById('referral_email_1').value
    const email2 = document.getElementById('referral_email_2').value
    const referralCountryPartnerId = document.getElementById('referral_referral_country_partner_id').value
    const deliveryCountryPartnerId = document.getElementById('referral_delivery_country_partner_id').value

    fetch(
      `/referrals/get_duplicate_clients?client_first_name=${clientFirstName}&client_last_name=${clientLastName}&email_1=${email1}&email_2=${email2}&referral_country_partner_id=${referralCountryPartnerId}&delivery_country_partner_id=${deliveryCountryPartnerId}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.duplicate_clients.length > 0) {
          let duplicateClients = ''
          data.duplicate_clients.forEach((client) => {
            duplicateClients += `
              First Name: ${client.firstName}
              Last Name: ${client.lastName}
              Email 1: ${client.email1}
              Email 2: ${client.email2}
              Referral Country Partner: ${client.referralCountryPartner}
              Delivery Country Partner: ${client.deliveryCountryPartner}`
          }
          )
          let answer = confirm(`Warning: This client may already exist in the system. Do you want to continue?\n${duplicateClients}`)
          if(answer){
            form.submit();
           }
        }
        else {
          form.submit();
        }
      })
  }

  calculateFee(event) {
    if (!this.ensureReferralPercentRange()) {
      return
    }
    const serviceFee = parseFloat(this.serviceFeeTarget.value)
    const referralFeePercent = parseFloat(this.referralFeePercentTarget.value)
    const referralFee = serviceFee * (referralFeePercent / 100)
    this.referralFeeTarget.value = referralFee.toFixed(2)
  }

  calculateTax(event) {
    if (!this.ensureTaxPercentRange()) {
      return
    }
    if (this.taxRequiredTarget.value === 'true') {
      const taxPercent = parseFloat(this.taxPercentTarget.value)
      const serviceFee = parseFloat(this.serviceFeeTarget.value)
      const referralFee = parseFloat(this.referralFeeTarget.value)
      const additionalFee = parseFloat(this.additionalFeeTarget.value) || 0.0
      const taxableAmount = (serviceFee - referralFee) + additionalFee
      const taxAmount = taxableAmount * (taxPercent / 100)
      this.taxAmountTarget.value = taxAmount.toFixed(2)
    } else {
      this.taxAmountTarget.value = '0.00'
    }
  }
}
